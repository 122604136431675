import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTagHistoryQuery } from "data/queries";
import { useTagContext } from "hooks/useTagContext";
import ItemHistoryRow from "./ItemHistoryRow";

const ItemHistoryTable = () => {
  const { t } = useTranslation();
  const { tag } = useTagContext();
  const [page, setPage] = React.useState(1);

  const { data } = useTagHistoryQuery(page);

  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (!data) return null;

  return (
    <Box borderRadius={2} sx={{ backgroundColor: "#F5F5F5" }} mt={2}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {t(
                "item-details-modal.accordion.item-history.table.history",
                "Owner",
              )}
            </TableCell>
            <TableCell sx={{ width: "120px" }}>
              {t(
                "item-details-modal.accordion.item-history.table.date",
                "Date",
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.transfers.map((transfer) => (
            <ItemHistoryRow
              key={transfer.id}
              transfer={transfer}
              chainId={tag.nft_chain_id}
            />
          ))}
        </TableBody>
      </Table>
      <Box p={2}>
        <Pagination
          sx={{
            "& > .MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
          page={page}
          onChange={handleChange}
          count={data?.pagy.pages ?? 1}
          variant="outlined"
          color="primary"
          siblingCount={0}
          boundaryCount={1}
          showFirstButton
          showLastButton
          size="small"
        />
      </Box>
    </Box>
  );
};

export default ItemHistoryTable;
