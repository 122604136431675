// Reference: https://habr.com/en/sandbox/209020/
export const preserveLineBreaks = (text?: string) => {
  if (!text) return undefined;
  const lines = text.split("\n");

  return lines
    .map((line, index): string => {
      // Check if the line is part of a list
      const isListItem = /^\s*[*\-+]\s+|^\s*\d+\.\s+/.test(line);
      const isNextLineListItem =
        index < lines.length - 1 &&
        /^\s*[*\-+]\s+|^\s*\d+\.\s+/.test(lines[index + 1]);

      if (isListItem || isNextLineListItem) return line;

      if (line.trim() === "\\") return line.replace("\\", "&nbsp;\n");

      return line + "&nbsp;\n";
    })
    .join("\n");
};
