import { createRef, useEffect } from "react";

interface NftVideoProps extends React.HTMLProps<HTMLVideoElement> {
  src: string;
}

const NftVideo = (props: NftVideoProps) => {
  let url = props.src;

  const ref = createRef<HTMLVideoElement>();

  useEffect(() => {
    const eventListener = () => {
      if (ref.current) {
        ref.current.play();
      }
    };

    window.addEventListener("focus", eventListener);

    return () => {
      window.removeEventListener("focus", eventListener);
    };

  }, [ref]);

  // streaming recompresses the video and introduces a long loading delay
  // if (url?.includes("ipfs.legitimate.tech")) {
  //   url += "?stream=true";
  // }

  if (url?.includes("ipfs://")) {
    url = url.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/");
  }

  return (
    <video
      ref={ref}
      loop
      autoPlay
      muted
      playsInline
      style={{
        width: "100%",
        borderRadius: 4,
        marginBottom: 16,
      }}
    >
      <source src={url} type="video/mp4" />
    </video>
  );
};

export default NftVideo;
