import { Box, Typography } from "@mui/material";
import { useTagContext } from "hooks/useTagContext";
import { useTranslation } from "react-i18next";
import NftImage from "./NftImage";

const TagReset = () => {
  const { t } = useTranslation();
  const { tag } = useTagContext();

  return (
    <Box pt={2}>
      <Typography textTransform="uppercase" variant="h6" fontWeight="medium">
        {t("tag-reset.transfer-initiated.title", "1. Transfer Initiated")}
      </Typography>
      <Box mt={2} mb={2.5} display="flex" gap={2}>
        <Box sx={{ width: "100px" }}>
          <NftImage src={tag.image_url!} />
        </Box>
        <Typography variant="body2">
          {t(
            "tag-reset.transfer-initiated.description",
            "This item and its associated digital identity is reset and ready to be claimed again.",
          )}
        </Typography>
      </Box>
      <Typography textTransform="uppercase" variant="h6" fontWeight="medium">
        {t("tag-reset.tap-to-claim.title", "2. Verify the Tag Again")}
      </Typography>
      <Typography variant="body2" mb={1}>
        {t(
          "tag-reset.tap-to-claim.description",
          "Please hover your device over the Legitimate Tag again and login to complete the transfer.",
        )}
      </Typography>
      <Box display="flex" sx={{ width: "100%" }} justifyContent="center">
        <img style={{ width: "100%" }} alt="Hover Again" src="/images/error-states/tap-again.webp" />
      </Box>
    </Box>
  );
};

export default TagReset;
