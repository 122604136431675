import React from "react";
import { TagContext } from "context/TagContext";
import { TTag } from "types/api";
import { DEFAULT_METADATA } from "utils/defaults";
import MetadataContent from "components/verification/MetadataContent";
import PreviewLoading from "components/PreviewLoading";
import { DisplayContext } from "context/DisplayContext";
import Dynamic from "components/Dynamic";

interface PreviewWebviewEvent {
  data: {
    tag: TTag;
  };
}

const Preview = () => {
  const [tag, setTag] = React.useState<TTag>();

  React.useEffect(() => {
    if (window.parent) {
      window.parent.postMessage({ ready: true }, "*");
    }

    const handleMessage = (event: PreviewWebviewEvent) => {
      if (event.data.tag) {
        setTag(event.data.tag);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  if (!tag) return <PreviewLoading />;

  return (
    <DisplayContext.Provider
      value={{
        state: {
          claimed: true,
          reset: false,
          addressModalOpen: false,
          showClaimed: false,
          consentModalOpen: false,
        },
        dispatch: () => {},
      }}
    >
      <TagContext.Provider value={{ tag, metadata: DEFAULT_METADATA }}>
        <Dynamic>
          <MetadataContent />
        </Dynamic>
      </TagContext.Provider>
    </DisplayContext.Provider>
  );
};

export default Preview;
