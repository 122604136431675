import { useMutation } from "@tanstack/react-query";
import { resolveOwnerName } from "./nft";
import useTagParams from "hooks/useTagParams";
import useDisplayContext from "hooks/useDisplayContext";
import { cacheTagData, clearTagData } from "utils/cache";
import { claimTag, resetTagOwnership, updateMarketingConsent } from "./tags";
import { TTag } from "types/api";

export const useClaimNFTMutation = () => {
  const { uid, ctr, cmac } = useTagParams();
  const { state, dispatch } = useDisplayContext();

  return useMutation({
    mutationKey: ["tags", "claim", cmac, ctr, uid],
    mutationFn: async ({
      account,
      email,
    }: {
      account?: string;
      email?: string;
    }) => {
      if (!account) {
        throw new Error("Wallet required to claim NFT");
      }

      if (state.claimed) {
        return;
      }

      const res = await claimTag({
        uid: uid!,
        ctr: ctr!,
        cmac: cmac!,
        account,
        email: email,
      });

      const ownerName = await resolveOwnerName(account);
      const tag: TTag = await res.json();

      // Even if the transaction fails, we still follow the happy path.
      cacheTagData({ tag, cmac: cmac!, ctr: ctr! });

      dispatch({ owner: account, ownerName });

      if (!tag.email_marketing_consent) {
        dispatch({ consentModalOpen: true });
      } else {
        dispatch({ claimed: true, showClaimed: true });
      }

      return res;
    },
    gcTime: 1000 * 60, // 1 mins
  });
};

export const useResetTagOwnershipMutation = () => {
  const { cmac, ctr, uid } = useTagParams();
  const { dispatch } = useDisplayContext();

  return useMutation({
    mutationKey: ["tags", "recover"],
    mutationFn: async () => {
      if (!cmac || !ctr || !uid) {
        throw new Error("Invalid tag parameters");
      }

      const response = await resetTagOwnership({ cmac, ctr, uid });

      if (!response.ok) {
        throw new Error("Tag cannot be verified.");
      }

      const responseBody = await response.json();

      // Return to the top of the screen
      window.screenTop && window.scrollTo(0, 0);
      clearTagData(uid);
      dispatch({ reset: true });

      return responseBody;
    },
  });
};

export const useMarketingConsentMutation = () => {
  const { uid, cmac, ctr } = useTagParams();

  return useMutation({
    mutationKey: ["tags", "marketing"],
    mutationFn: async (consent: boolean) => {
      if (!uid || !cmac || !ctr) {
        throw new Error("Invalid tag parameters");
      }

      const response = await updateMarketingConsent({
        uid,
        cmac,
        ctr,
        consent,
      });

      if (!response.ok) {
        throw new Error("Failed to update marketing consent");
      }

      clearTagData(uid);

      return response;
    },
  });
};
