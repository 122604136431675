import { TFunction } from "i18next";

export enum TagVerificationType {
  ProgrammingQC = "programming_qc",
  UserViewTap = "user_view",
}

export const getVerifyTypeText = (verifyType: string, t: TFunction) => {
  switch (verifyType) {
    case TagVerificationType.ProgrammingQC:
      return t("verify-types.programming-qc", "Tag Programmed");
    case TagVerificationType.UserViewTap:
      return t("verify-types.user-view-tap", "Page Viewed");
    default:
      return verifyType;
  }
};
