import React from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import BottomModal from "./BottomModal";
import useDisplayContext from "hooks/useDisplayContext";
import { useMarketingConsentMutation } from "data/mutations";

const ConsentModal: React.FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useDisplayContext();
  const [consentGranted, setConsentGranted] = React.useState(true);
  const mutation = useMarketingConsentMutation();

  const handleClose = React.useCallback(() => {
    dispatch({ consentModalOpen: false, claimed: true, showClaimed: true });
  }, [dispatch]);

  const handleSubmit = React.useCallback(async () => {
    try {
      mutation.mutateAsync(consentGranted);
    } finally {
      handleClose();
    }
  }, [mutation, consentGranted, handleClose]);

  return (
    <BottomModal open={state.consentModalOpen} handleClose={handleClose}>
      <Typography variant="h5" component="h5">
        {t("consent-modal.title", "Keep Me Updated")}
      </Typography>

      <FormGroup sx={{ mt: 2, mb: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={mutation.isPending}
              checked={consentGranted}
              onChange={(_, checked) => {
                setConsentGranted(checked);
              }}
            />
          }
          label={
            <Typography variant="body2">
              {t(
                "consent-modal.marketing-consent-text",
                "Send me emails from Legitimate and its partners. Unsubscribe anytime.",
              )}
            </Typography>
          }
        />
      </FormGroup>

      <Button
        variant="contained"
        fullWidth
        onClick={handleSubmit}
        startIcon={
          mutation.isPending ? (
            <CircularProgress color="inherit" size={16} />
          ) : undefined
        }
        disabled={mutation.isPending}
      >
        {t("consent-modal.button.text", "Save")}
      </Button>
    </BottomModal>
  );
};

export default ConsentModal;
