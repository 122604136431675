import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import { Language } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { useEffect, useState } from "react";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { locale } = useDynamicContext();
  const [initLanguage, setInitLanguage] = useState(false); // tracks updates so language updates don't trigger useEffect loop

  useEffect(() => {
    // dynamic doesn't pick up on the initial language so we have to set it manually
    if (!initLanguage && i18n.language) {
      locale.changeLanguage(i18n.language.slice(0, 2));
      setInitLanguage(true);
    }
  }, [i18n.language, locale, initLanguage]);

  // We use an `any` type here, because mui is not helpful.
  // eslint-disable-next-line
  const handleChange = (e: any) => {
    i18n.changeLanguage(e.target.value);
    locale.changeLanguage(e.target.value.slice(0, 2));
  };

  return (
    <CustomSelect
      IconComponent={Language}
      onChange={handleChange}
      value="" // hack to hide the text so select is icon only
      variant="filled"
    >
      <CustomItem value="en-US">
        <ListItemIcon>🇺🇸</ListItemIcon>
        <ListItemText>English</ListItemText>
      </CustomItem>
      <CustomItem value="ja-JP">
        <ListItemIcon>🇯🇵</ListItemIcon>
        <ListItemText>日本語</ListItemText>
      </CustomItem>
    </CustomSelect>
  );
};

const CustomSelect = styled(Select)`
  border-radius: 50px;

  // hides underline
  ::before,
  ::after {
    display: none;
  }

  .MuiSelect-select {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .MuiListItemIcon-root {
    display: none;
  }

  .MuiSvgIcon-root {
    left: 12px;
    font-size: 20px;
  }

  .MuiSelect-iconOpen {
    transform: none;
  }
`;

const CustomItem = styled(MenuItem)`
  .MuiListItemIcon-root {
    min-width: 24px;
  }
`;

export default LanguageSwitcher;
