import { Box, Typography } from "@mui/material";
import MuiMarkdown from "mui-markdown";

import { useTagContext } from "hooks/useTagContext";

import NFTImage from "./NftImage";
import NftVideo from "./NftVideo";
import { preserveLineBreaks } from "utils/text";

const Nft: React.FC = () => {
  const { tag } = useTagContext();

  return (
    <Box>
      <Typography variant="h5" mt={1} mb={2}>
        {tag.name}
      </Typography>
      {tag.animation_url ? (
        <NftVideo src={tag.animation_url} />
      ) : (
        <NFTImage src={tag.image_url!} />
      )}
      <Typography variant="body2">
        <MuiMarkdown>{preserveLineBreaks(tag.description)}</MuiMarkdown>
      </Typography>
    </Box>
  );
};

export default Nft;
