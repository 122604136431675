import { useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { useTagContext } from "hooks/useTagContext";
import useDisplayContext from "hooks/useDisplayContext";

import BuilderContent from "./builder/BuilderContent";
import UnlockContent from "./UnlockContent";
import { useTranslation } from "react-i18next";

const ExclusiveContent = () => {
  const { tag } = useTagContext();
  const { state } = useDisplayContext();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const builderPath = tag.builder_io_url;
  const builderApiKey = tag.builder_io_api_key;

  const tamper = searchParams.get("tamper");

  if (!builderPath || !builderApiKey) return null;

  return (
    <Box
      mt={4}
      border={state.claimed ? "" : "1px solid #FFFFFF"}
      pb={2}
      borderRadius="4px"
    >
      {!state.claimed ? (
        <UnlockContent />
      ) : tamper === "OO" ? (
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "medium" }}>
            {t(
              "exclusive-content.tag-tamper.item-opened.title",
              "This item has been opened",
            )}
          </Typography>
          <Typography>
            {t(
              "exclusive-content.tag-tamper.item-opened.description",
              "The exclusive content for this product is only available when the seal on the Legitimate Tag has not been opened. Please contact the manufacturer for more information.",
            )}
          </Typography>
          <Box sx={{ filter: "blur(5px)" }}>
            <BuilderContent
              builderPath={builderPath}
              builderAPIKey={builderApiKey}
            />
          </Box>
        </Box>
      ) : (
        <BuilderContent
          builderPath={builderPath}
          builderAPIKey={builderApiKey}
        />
      )}
    </Box>
  );
};

export default ExclusiveContent;
