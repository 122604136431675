import { Box } from "@mui/material";

import Nft from "components/Nft";
import ClaimNow from "components/ClaimNow";
import ExclusiveContent from "components/ExclusiveContent";
import TagReset from "components/TagReset";
import Header from "components/Header";
import Footer from "components/Footer";

import useDisplayContext from "hooks/useDisplayContext";
import { useTagContext } from "hooks/useTagContext";
import AddressModal from "components/modals/AddressModal";
import LanguageSwitcher from "components/LanguageSwitcher";
import TagTamper from "components/tamper/TagTamper";
import ClaimSuccess from "components/ClaimSuccess";
import ItemDetailsButton from "components/ItemDetailsButton";
import ViewCollectionButton from "components/ViewCollectionButton";
import ConsentModal from "components/modals/ConsentModal";
import useTagViewGAEvent from "hooks/useTagViewGAEvent";
import usePostAuth from "hooks/usePostAuth";

const MetadataContent = () => {
  const { tag } = useTagContext();
  const { state, dispatch } = useDisplayContext();

  // place GA within the component so that it is only triggered when the verification is successful
  useTagViewGAEvent(tag);
  usePostAuth();

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Header right={<LanguageSwitcher />} logoUrl={tag.header_logo_url} />
        <TagTamper />
        {state.reset ? (
          <TagReset />
        ) : (
          <>
            <Nft />
            <ItemDetailsButton />
            <ViewCollectionButton />
            <ExclusiveContent />
            <AddressModal
              open={state.addressModalOpen}
              handleClose={() => dispatch({ addressModalOpen: false })}
            />
            <ConsentModal />
          </>
        )}
      </Box>
      <Footer />
      <ClaimNow />
      <ClaimSuccess />
    </>
  );
};

export default MetadataContent;
