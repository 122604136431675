import { SdkViewSectionType, SdkViewType } from "@dynamic-labs/sdk-api-core";

export const DYNAMIC_BASE_VIEWS = [
  {
    type: SdkViewType.Login,
    sections: [
      { type: SdkViewSectionType.Email },
      { type: SdkViewSectionType.Separator, label: "Or" },
      { type: SdkViewSectionType.Social, defaultItem: "google" },
    ],
  },
];

export const DYNAMIC_WEB3_VIEWS = [
  {
    type: SdkViewType.Login,
    sections: [
      { type: SdkViewSectionType.Email },
      { type: SdkViewSectionType.Separator, label: "Or" },
      { type: SdkViewSectionType.Social, defaultItem: "google" },
      { type: SdkViewSectionType.Separator, label: "Or" },
      { type: SdkViewSectionType.Wallet },
    ],
  },
];
