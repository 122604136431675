import React from "react";
import { useLocation } from "react-router-dom";
import { TTag } from "types/api";

const useTagViewGAEvent = (tag?: TTag) => {
  const [shouldSendEvent, setShouldSendEvent] = React.useState(true);
  const location = useLocation();

  React.useEffect(() => {
    if (!shouldSendEvent || !tag) {
      return;
    }

    dataLayer.push({
      event: "tagview",
      "lgt.tagId": tag.uid,
      "lgt.tagTemplateId": tag.template_id,
    });

    setShouldSendEvent(false);
  }, [location, tag, shouldSendEvent]);

  return {
    hasSentEvent: !shouldSendEvent,
  };
};

export default useTagViewGAEvent;
