import { Box, Typography } from "@mui/material";
import Header from "components/Header";
import Footer from "components/Footer";
import NftImage from "components/NftImage";

const AppClipPage = () => {

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Header />
        <Box>
          <Typography variant="h5" mt={1} mb={2}>
            App Clip Invocation
          </Typography>
          <NftImage src="https://ipfs.legitimate.tech/ipfs/Qmc5z2b9MmyhJBYMk7nj9tTrAZdNHpct1UnMuaRZahg6JA" />
          <Typography variant="body2">
            This page is used for testing App Clip Invocation
          </Typography>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default AppClipPage;
