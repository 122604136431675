import React from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlineSharp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import useDisplayContext from "hooks/useDisplayContext";

const ClaimSuccess = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useDisplayContext();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch({ showClaimed: false });
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, state.showClaimed]);

  if (!state.showClaimed) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "16px",
        width: "100%",
      }}
    >
      <Box
        p={2}
        mx={2}
        borderRadius="4px"
        sx={{
          backgroundColor: "#FFFFFF",
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <CheckCircleOutlineSharp sx={{ fontSize: "32px", color: "#22C55E" }} />
        <Box>
          <Typography sx={{ color: "#5D5F5F" }} variant="body2">
            {t(
              "claim-success.description",
              "The item has been successfully added to your collection.",
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ClaimSuccess;
