import React from "react";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { useIsMutating } from "@tanstack/react-query";
import {
  Box,
  Button,
  CircularProgress,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useDisplayContext from "hooks/useDisplayContext";
import { useTagContext } from "hooks/useTagContext";
import { lightTheme } from "styles/theme";
import { IS_IN_WEBVIEW } from "utils/webview";
import useTagParams from "hooks/useTagParams";

const ClaimNow = () => {
  const { t } = useTranslation();
  const { setShowAuthFlow } = useDynamicContext();
  const { uid, ctr, cmac } = useTagParams();
  const isMutatingClaim = useIsMutating({
    mutationKey: ["tags", "claim", cmac, ctr, uid],
  });
  const { state } = useDisplayContext();
  const { tag } = useTagContext();

  const handleClaim = React.useCallback(() => {
    if (IS_IN_WEBVIEW) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ type: "claim" }));
    } else {
      setShowAuthFlow(true);
    }
  }, [setShowAuthFlow]);

  if (state.claimed || state.reset) return null;

  return (
    <Box
      sx={{
        width: "100%",
        padding: 2,
        position: "sticky",
        bottom: 0,
        backgroundColor: "#FFFFFF",
        borderRadius: "16px 16px 0 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        align="center"
        variant="body2"
        sx={{ mb: 2, color: "#000000", maxWidth: '327px' }}
      >
        {t("claim-now.description", "Add {{name}} to your collection.", {
          name: tag.name,
        })}
      </Typography>
      <ThemeProvider theme={lightTheme}>
        <Button
          disabled={isMutatingClaim > 0}
          startIcon={
            isMutatingClaim > 0 ? (
              <CircularProgress color="inherit" size={16} />
            ) : undefined
          }
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleClaim}
          sx={{
            py: 1,
          }}
        >
          {t("claim-now.button.text", "Add to Your Collection")}
        </Button>
      </ThemeProvider>
    </Box>
  );
};

export default ClaimNow;
