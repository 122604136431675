import { ThemeOptions, createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";

export const SANS_SERIF_FONTS = [
  "ABC Favorit",
  "Arial",
  "sans-serif",
].join(", ");

const baseTheme: ThemeOptions = {
  palette: {
    error: { main: "#F44336" },
    warning: { main: "#ED6C02" },
    info: { main: "#2196F3" },
    success: { main: "#5CA968" },
  },
  typography: {
    h1: {
      fontFamily: SANS_SERIF_FONTS,
      fontWeight: 500,
    },
    h2: {
      fontFamily: SANS_SERIF_FONTS,
      fontWeight: 500,
    },
    h3: {
      fontFamily: SANS_SERIF_FONTS,
      fontWeight: 500,
    },
    h4: {
      fontSize: "34px",
      fontFamily: SANS_SERIF_FONTS,
      fontWeight: 500,
      lineHeight: "42px",
    },
    h5: {
      fontSize: "22px",
      lineHeight: "30px",
      fontFamily: SANS_SERIF_FONTS,
      fontWeight: 500,
    },
    h6: {
      fontFamily: SANS_SERIF_FONTS,
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "32px",
    },
    subtitle2: {
      fontFamily: SANS_SERIF_FONTS,
    },
    body1: {
      fontFamily: SANS_SERIF_FONTS,
      fontWeight: 400,
    },
    button: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "26px",
    },
    caption: {
      fontSize: "12px",
    },
    allVariants: {
      fontFamily: SANS_SERIF_FONTS,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "initial",
          borderRadius: 4,
          WebkitTapHighlightColor: "transparent",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "inherit",
          fontFamily: SANS_SERIF_FONTS,
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiCssBaseline: {
      styleOverrides: `

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      `,
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          fontFamily: SANS_SERIF_FONTS,
        },
      },
    },
  },
};

export const darkTheme = createTheme(
  deepmerge(baseTheme, {
    palette: {
      mode: "dark",
      primary: {
        main: "#FFFFFF",
      },
      secondary: {
        main: "#000000",
      },
      background: {
        default: "#000000",
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          outlined: {
            background: "#F1F3FF1A",
            border: "1px solid #FFFFFF",
          },
        },
      },
    },
  }),
);

export const lightTheme = createTheme(
  deepmerge(baseTheme, {
    palette: {
      mode: "light",
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#FFFFFF",
      },
      background: {
        default: "#FFFFFF",
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          outlined: {
            color: "#000000",
            border: "1px solid #000000",
          },
        },
      },
    },
  }),
);
