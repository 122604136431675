import { Typography, TypographyProps } from "@mui/material";

const SectionTitle: React.FC<TypographyProps> = ({ children, ...props }) => {
  return (
    <Typography
      variant="h5"
      {...props}
    >
      {children}
    </Typography>
  );
};

export default SectionTitle;
