import { Box, SwipeableDrawer, ThemeProvider, styled } from "@mui/material";

import { lightTheme } from "styles/theme";

interface BottomModalProps {
  open: boolean;
  handleClose(): void;
  children: React.ReactNode;
}

const BottomModal: React.FC<BottomModalProps> = ({
  open,
  handleClose,
  children,
}) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <BottomSheet
        anchor="bottom"
        open={open}
        onOpen={() => {}}
        onClose={handleClose}
      >
        <Box
          alignSelf="center"
          style={{
            width: 36,
            height: 5,
            borderRadius: 2,
            background: "#3C3C434D",
            marginBottom: 32,
          }}
        />
        {children}
      </BottomSheet>
    </ThemeProvider>
  );
};

const BottomSheet = styled(SwipeableDrawer)`
  & > div {
    padding: 8px 16px 16px;
    border-radius: 10px 10px 0 0;
    max-height: 80vh;
  }
`;

export default BottomModal;
