import { Box, Typography } from "@mui/material";
import SectionTitle from "components/SectionTitle";
import { Trans } from "react-i18next";

const TagTamper = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const tamper = searchParams.get("tamper");

  if (!tamper) {
    return null;
  }

  if (tamper !== 'CC') {
    return <Box padding={2} my={2} border="1px solid #FFFFFF" borderRadius="4px">
      <SectionTitle>
        <Trans key="tagtamper.opened.title">Legitimate Seal Opened</Trans>
      </SectionTitle>
      <Typography variant="body2">
        <Trans key="tagtamper.opened.description">
          The seal on the Legitimate Tag for this item has been opened or tampered with.
        </Trans>
      </Typography>
    </Box>;

  }

  return <Box padding={2} my={2} border="1px solid #FFFFFF" borderRadius="4px">
    <SectionTitle>
      <Trans key="tagtamper.sealed.title">
        Legitimate Seal Verified
      </Trans>
    </SectionTitle>
    <Typography variant="body2">
      <Trans key="tagtamper.sealed.description">
        This item is electronically verified by this Legitimate Tag to be sealed and unopened.
      </Trans>
    </Typography>
  </Box>;
};

export default TagTamper;

