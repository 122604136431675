import React from "react";

import { useClaimNFTMutation } from "data/mutations";

const usePostAuth = () => {
  const claimNFTMutation = useClaimNFTMutation();

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleWebviewEvent = async (event: any) => {
      try {
        const { name, data } = event.data;

        if (name === "dynamicLogin" && data?.address) {
          await claimNFTMutation.mutateAsync(data.address);
        }
      } catch (err) {
        console.warn(err);
      }
    };

    window.addEventListener("webviewMessage", handleWebviewEvent, true);

    return () => {
      window.removeEventListener("webviewMessage", handleWebviewEvent, true);
    };
  }, [claimNFTMutation]);
};

export default usePostAuth;
