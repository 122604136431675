import React from "react";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import BottomModal from "./BottomModal";
import { useClaimNFTMutation } from "data/mutations";
import { WALLET_ADDRESS_REGEX } from "utils/constants";
import { resolveAddressFromENS } from "data/nft";

interface AddressModalProps {
  open: boolean;
  handleClose(): void;
}

const AddressModal: React.FC<AddressModalProps> = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const [address, setAddress] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const mutation = useClaimNFTMutation();

  const handleInputChange = React.useCallback(async (input: string) => {
    setAddress(input);

    if (input.includes(".eth")) {
      setLoading(true);

      const resolvedAddress = await resolveAddressFromENS(input);
      setAddress(resolvedAddress || input);
      setLoading(false);
    }
  }, []);

  const handleSubmit = React.useCallback(async () => {
    await mutation.mutateAsync({ account: address });
    handleClose();
  }, [mutation, address, handleClose]);

  return (
    <BottomModal open={open} handleClose={handleClose}>
      <Typography variant="h6" mb={1}>
        {t("address-modal.title", "Having trouble connecting your wallet?")}
      </Typography>
      <Typography variant="body2" color="textSecondary" mb={2}>
        {t(
          "address-modal.description",
          "If you have a wallet address or ENS name, you may enter it below to continue.",
        )}
      </Typography>
      <TextField
        value={address}
        onChange={(e) => handleInputChange(e.target.value)}
        placeholder="0xAbCdEf…123456 / legitimatetech.eth"
        autoComplete="off"
        spellCheck="false"
        autoCorrect="off"
        autoCapitalize="off"
        label={t("address-modal.input-label", "Wallet Address or ENS Name")}
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        fullWidth
        onClick={handleSubmit}
        disabled={
          loading || !address.match(WALLET_ADDRESS_REGEX) || mutation.isPending
        }
        startIcon={
          mutation.isPending ? (
            <CircularProgress color="inherit" size="16px" />
          ) : undefined
        }
      >
        {t("address-modal.button.claim-with-address", "Use Wallet Address")}
      </Button>
    </BottomModal>
  );
};

export default AddressModal;
