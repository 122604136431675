import React from "react";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { LibraryMusic, VideoLibrary, Image, Lock } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SectionTitle from "./SectionTitle";

const UnlockContent = () => {
  const { t } = useTranslation();
  const { setShowAuthFlow } = useDynamicContext();

  const handleUnlock = React.useCallback(() => {
    setShowAuthFlow(true);
  }, [setShowAuthFlow]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      p={2}
      pb={0}
    >
      <SectionTitle>
        {t("exclusive-content.unlock.title", "Exclusive Content")}
      </SectionTitle>
      <Box display="flex" justifyContent="center" margin="48px 0" gap={2}>
        <VideoLibrary />
        <Image />
        <LibraryMusic />
      </Box>
      <Typography sx={{ textAlign: "center", marginBottom: 3 }}>
        {t(
          "unlock-content.description.title",
          "Add to your collection to unlock exclusive content",
        )}
      </Typography>
      <Button variant="outlined" startIcon={<Lock />} onClick={handleUnlock}>
        {t("unlock-content.description.button", "Unlock Content")}
      </Button>
    </Box>
  );
};

export default UnlockContent;
