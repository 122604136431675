import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import useDisplayContext from "hooks/useDisplayContext";
import { IS_IN_WEBVIEW } from "utils/webview";
import { useTagContext } from "hooks/useTagContext";
import { LoginType } from "types/api";

const ViewCollectionButton = () => {
  const { state } = useDisplayContext();
  const { t } = useTranslation();
  const { tag } = useTagContext();

  const handleButtonPress = () => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({ type: "openCollection" }),
    );
  };

  if (!state.claimed || tag.login_type === LoginType.OFF) {
    return null;
  }

  return (
    <Button
      href={
        !IS_IN_WEBVIEW
          ? `https://collection.legitimate.tech/${state.owner}`
          : undefined
      }
      onClick={IS_IN_WEBVIEW ? handleButtonPress : undefined}
      fullWidth
      sx={{ mt: 1 }}
    >
      {t("view-collection-button.text", "Your Collection")}
    </Button>
  );
};

export default ViewCollectionButton;
