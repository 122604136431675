import {
  DynamicContextProvider,
  SortWallets,
} from "@dynamic-labs/sdk-react-core";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { useTagContext } from "hooks/useTagContext";
import { DYNAMIC_BASE_VIEWS, DYNAMIC_WEB3_VIEWS } from "utils/dynamic";
import useDisplayContext from "hooks/useDisplayContext";
import ja from "../assets/dynamic-ja.json";
import en from "../assets/dynamic-en.json";
import { LoginType } from "types/api";
import { useClaimNFTMutation } from "data/mutations";

const DEFAULT_DYNAMIC_ENV_ID =
  import.meta.env.VITE_DYNAMIC_ENV_ID || "8c1a40e8-1150-4e61-bae7-dc1f2fb8c5b3";

interface DynamicProps {
  children: React.ReactNode;
}

const Dynamic: React.FC<DynamicProps> = ({ children }) => {
  const { tag } = useTagContext();
  const { dispatch } = useDisplayContext();
  const claimNFTMutation = useClaimNFTMutation();

  return (
    <DynamicContextProvider
      locale={{ en, ja }}
      settings={{
        apiBaseUrl: "https://dynamic.legitimate.tech/api/v0",
        environmentId: DEFAULT_DYNAMIC_ENV_ID,
        initialAuthenticationMode: "connect-only",
        enableVisitTrackingOnConnectOnly: true,
        walletsFilter: SortWallets([
          "rainbow",
          "walletconnect",
          "metamask",
          "trust",
          "ledger",
          "core",
        ]),
        defaultNumberOfWalletsToShow: 6,
        newToWeb3WalletChainMap: {
          primary_chain: "ETH",
          wallets: { evm: "rainbow" },
        },
        walletConnectors: [EthereumWalletConnectors],
        privacyPolicyUrl: "https://www.legitimate.tech/privacy",
        termsOfServiceUrl: "https://www.legitimate.tech/terms",
        overrides: {
          views:
            tag.login_type === LoginType.EMAIL_SOCIAL_WEB3
              ? DYNAMIC_WEB3_VIEWS
              : DYNAMIC_BASE_VIEWS,
        },
        events: {
          onAuthSuccess: async ({ primaryWallet, handleLogOut, user }) => {
            if (
              primaryWallet?.address &&
              primaryWallet.isAuthenticated &&
              !claimNFTMutation.isPending
            ) {
              await claimNFTMutation.mutateAsync({
                account: primaryWallet.address,
                email: user.email,
              });
              handleLogOut();
            }
          },
          onAuthFlowCancel: () => {
            if (tag.login_type === LoginType.EMAIL_SOCIAL_WEB3) {
              dispatch({ addressModalOpen: true });
            }
          },
        },
      }}
    >
      {children}
    </DynamicContextProvider>
  );
};

export default Dynamic;
