import { Box, Link, Typography } from "@mui/material";
import useDisplayContext from "hooks/useDisplayContext";
import { useTagContext } from "hooks/useTagContext";
import { useTranslation } from "react-i18next";
import { LoginType } from "types/api";
import {
  generateAddressLink,
  generateAssetLink,
  truncateAddress,
} from "utils/chains";

const ItemMeta = () => {
  const { t } = useTranslation();
  const { tag } = useTagContext();
  const { state } = useDisplayContext();

  return (
    <Box mb={2.5}>
      {tag.nft_contract_address && tag.nft_chain_id && tag.nft_token_id && (
        <Box display="flex" justifyContent="space-between" mb={1}>
          <div>
            <Link
              href={generateAddressLink(
                tag.nft_chain_id!,
                tag.nft_contract_address!,
              )}
              target="_blank"
              sx={{ textDecorationColor: "#000000" }}
            >
              {truncateAddress(tag.nft_contract_address!)}
            </Link>
            <Typography variant="caption" component="p">
              {t(
                "item-details-modal.meta.nft-contract-address",
                "Collection ID",
              )}
            </Typography>
          </div>
          <div>
            <Link
              href={generateAssetLink(
                tag.nft_chain_id!,
                tag.nft_contract_address!,
                String(tag.nft_token_id!),
              )}
              target="_blank"
              sx={{ textDecorationColor: "#000000" }}
            >
              {tag.nft_token_id}
            </Link>
            <Typography variant="caption" component="p">
              {t("item-details-modal.meta.nft-id", "Serial Number")}
            </Typography>
          </div>
        </Box>
      )}
      {state.owner && state.ownerName && tag.login_type !== LoginType.OFF && (
        <div>
          <Link
            href={`https://collection.legitimate.tech/${state.owner}`}
            target="_blank"
            sx={{ textDecorationColor: "#000000" }}
          >
            {state.ownerName}
          </Link>
          <Typography variant="caption" component="p">
            {t("item-details-modal.meta.owned-by", "Owned By")}
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default ItemMeta;
