import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Lock } from "@mui/icons-material";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import useDisplayContext from "hooks/useDisplayContext";
import ItemDetailsModal from "./modals/ItemDetailsModal";

const ItemDetailsButton = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);
  const { state } = useDisplayContext();
  const { setShowAuthFlow } = useDynamicContext();

  const handleViewDetails = React.useCallback(() => {
    if (state.claimed) {
      setModalOpen(true);
    } else {
      setShowAuthFlow(true);
    }
  }, [state.claimed, setShowAuthFlow]);

  return (
    <>
      <Button
        sx={{ mt: 2 }}
        variant="outlined"
        fullWidth
        onClick={handleViewDetails}
        startIcon={!state.claimed ? <Lock /> : undefined}
      >
        {t("item-history.button-text", "View Details")}
      </Button>
      <ItemDetailsModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default ItemDetailsButton;
