import React from "react";
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTagVerificationsQuery } from "data/queries";
import { useTranslation } from "react-i18next";
import { getVerifyTypeText } from "utils/tables";

const TagReadsTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(1);
  const { data } = useTagVerificationsQuery(page);

  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (!data || data?.verifications.length === 0) return null;

  return (
    <Box borderRadius={2} sx={{ backgroundColor: "#F5F5F5" }} mt={2}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {t(
                "item-details-modal.accordion.tag-reads.table.type",
                "Verification Type",
              )}
            </TableCell>
            <TableCell sx={{ width: "120px" }}>
              {t("item-details-modal.accordion.tag-reads.table.date", "Date")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.verifications.map((v) => (
            <TableRow>
              <TableCell>
                <Typography variant="body2">
                  {getVerifyTypeText(v.verify_type, t)}
                </Typography>
                {v.country && (
                  <Typography variant="body2">
                    {[v.city, v.region, v.country].filter(Boolean).join(", ")}
                  </Typography>
                )}
              </TableCell>
              <TableCell sx={{ width: "120px" }}>
                <Typography variant="body2">
                  {new Date(v.created_at).toLocaleDateString()}
                </Typography>
                <Typography variant="body2">
                  {new Date(v.created_at).toLocaleTimeString()}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box p={2}>
        <Pagination
          sx={{
            "& > .MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
          page={page}
          onChange={handleChange}
          count={data?.pagy.pages ?? 1}
          variant="outlined"
          color="primary"
          siblingCount={0}
          boundaryCount={1}
          showFirstButton
          showLastButton
          size="small"
        />
      </Box>
    </Box>
  );
};

export default TagReadsTable;
