import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useResetTagOwnershipMutation } from "data/mutations";

const TransferOwnership: React.FC = () => {
  const { t } = useTranslation();
  const mutation = useResetTagOwnershipMutation();

  const handleReset = () => {
    mutation.mutateAsync();
  };

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Typography sx={{ color: "#00000099" }} variant="body2">
        {t(
          "item-details-modal.accordion.transfer-ownership.description",
          "Transfer or update the ownership of this item and its associated digital identity.",
        )}
      </Typography>
      <Button
        disableElevation
        sx={{ mt: 1 }}
        variant="outlined"
        fullWidth
        onClick={handleReset}
        disabled={mutation.isPending}
        startIcon={
          mutation.isPending ? (
            <CircularProgress color="inherit" size={16} />
          ) : undefined
        }
      >
        {t(
          "item-details-modal.accordion.transfer-ownership.button.text",
          "Reset and Transfer",
        )}
      </Button>
      {mutation.error && (
        <Typography variant="body2" sx={{ mt: 2 }}>
          {mutation.error.message ||
            t(
              "item-details-modal.accordion.transfer-ownership.error-fallback",
              "An error has occured.",
            )}
        </Typography>
      )}
    </Box>
  );
};

export default TransferOwnership;
