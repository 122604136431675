import { useTranslation } from "react-i18next";
import { TableRow, TableCell, Typography, Link } from "@mui/material";
import { ITransfer } from "data/queries";
import {
  NETWORK,
  NETWORK_NAME_BY_CHAIN_ID,
  generateAddressLink,
  truncateAddress,
} from "utils/chains";

interface ItemHistoryRowProps {
  chainId?: NETWORK;
  transfer: ITransfer;
}

const ItemHistoryRow: React.FC<ItemHistoryRowProps> = ({
  transfer,
  chainId,
}) => {
  const { t } = useTranslation();

  return (
    <TableRow key={transfer.id}>
      <TableCell>
        {transfer.transfer_type === "mint" ? (
          chainId ? (
            t(
              "item-details-modal.accordion.item-history.table.event-mint",
              "Created with Legitimate on {{chain}}",
              { chain: NETWORK_NAME_BY_CHAIN_ID[chainId] },
            )
          ) : (
            t(
              "item-details-modal.accordion.item-history.table.event-mint-no-nft",
              "Created with Legitimate",
            )
          )
        ) : (
          <Link
            href={generateAddressLink(chainId!, transfer.receiver_address!)}
            target="_blank"
          >
            {truncateAddress(transfer.receiver_address!)}
          </Link>
        )}
      </TableCell>
      <TableCell sx={{ width: "120px" }}>
        <Typography variant="body2">
          {new Date(transfer.created_at).toLocaleDateString()}
        </Typography>
        <Typography variant="body2">
          {new Date(transfer.created_at).toLocaleTimeString()}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default ItemHistoryRow;
