import React from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  Fade,
  ThemeProvider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useTagContext } from "hooks/useTagContext";

import ItemMeta from "../item-details/ItemMeta";
import ItemHistoryTable from "../item-details/ItemHistoryTable";
import TagReadsTable from "../item-details/TagReadsTable";
import TransferOwnership from "../item-details/TransferOwnership";
import { chainHasSimplehash } from "utils/chains";
import { lightTheme } from "styles/theme";
import { Close } from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";
import { LoginType } from "types/api";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: Exclude<
      NonNullable<React.ReactNode>,
      string | number | boolean | Iterable<React.ReactNode>
    >;
  },
  ref: React.Ref<unknown>,
) {
  return <Fade timeout={300} ref={ref} {...props} />;
});

interface ItemDetailsModalProps {
  open: boolean;
  handleClose(): void;
}

type AccordionSection = "history" | "tag-reads" | "";

const ItemDetailsModal: React.FC<ItemDetailsModalProps> = ({
  open,
  handleClose,
}) => {
  const [expanded, setExpanded] = React.useState<AccordionSection>("tag-reads");
  const { tag } = useTagContext();
  const { t } = useTranslation();

  const handleChange = (panel: AccordionSection) => () => {
    setExpanded(panel);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog
        open={open}
        fullScreen
        sx={{ background: "rgba(0, 0, 0, 0.2)", pt: 4 }}
        PaperProps={{ sx: { borderRadius: "16px 16px 0 0" } }}
        TransitionComponent={Transition}
      >
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography variant="h5" fontFamily="ABC Favorit">
              {t("item-details-modal.title", "Item Details")}
            </Typography>

            <Box onClick={handleClose}>
              <Close sx={{ color: "#323232" }} />
            </Box>
          </Box>
          <ItemMeta />
          {tag.login_type !== LoginType.OFF && <TransferOwnership />}
          <Accordion
            expanded={expanded === "tag-reads"}
            onChange={
              expanded === "tag-reads"
                ? handleChange("")
                : handleChange("tag-reads")
            }
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {t("item-details-modal.accordion.tag-reads.title", "Tag Verifications")}
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: "#00000099" }} variant="body2">
                {t(
                  "item-details-modal.accordion.tag-reads.description",
                  "This tag has been verified {{readCount}} times. Locations are approximate.",
                  { readCount: tag.counter ?? 0 },
                )}
              </Typography>
              <TagReadsTable />
            </AccordionDetails>
          </Accordion>
          {chainHasSimplehash(tag.nft_chain_id!) && (
            <Accordion
              expanded={expanded === "history"}
              onChange={
                expanded === "history"
                  ? handleChange("")
                  : handleChange("history")
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {t(
                  "item-details-modal.accordion.item-history.title",
                  "Ownership History",
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: "#00000099" }} variant="body2">
                  {t(
                    "item-details-modal.accordion.item-history.description",
                    "The ownership history below reflects the creation of the item as well as the owners of the item and its associated digital identity. This information is tracked on the blockchain and cannot be modified.",
                  )}
                </Typography>
                <ItemHistoryTable />
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

export default ItemDetailsModal;
